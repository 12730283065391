import { init, send } from "emailjs-com"

init("user_NZkqCuvwVsSHe5wBbrSff")

const SERVICE_ID = "service_x5icqc5"
export const mail_notification = (template_id, values = {}, actions = {}) => {
  send(SERVICE_ID, template_id, values)
    .then(() => {
      for (const action in actions) {
        if (typeof actions[action] === "function") {
          actions[action]()
        }
      }
    })
    .catch(error => {
      alert(error)
    })
}
