import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import Container from "../../utils/container"
import Button from "../../utils/button"
import Popup from "../../utils/popup"
import * as Yup from "yup"
import ctl from "@netlify/classnames-template-literals"
import { mail_notification } from "../../../helpers/mail/notification"

const InquiryForm = () => {
  const [messageSent, setMessageSent] = useState(false)
  const inputStyle = ctl(`
  border-b
  border-black
  block
  w-full
  pb-2
  font-light
  mb-8
  focus:outline-none
  md:text-lg
  text-base
  `)

  const radioStyle = ctl(`
  inline-block
  mr-4
  `)
  return (
    <Container>
      <Popup
        topText="Got that."
        bottomText="We’ll write back."
        isOpen={messageSent}
        closePopup={() => setMessageSent(false)}
        showShareNow={false}
      />
      <Formik
        initialValues={{
          email: "",
          organization: "",
          question: "",
          name: "",
          inquiry_type: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string(),
          organization: Yup.string(),
          question: Yup.string(),
          name: Yup.string(),
          inquiry_type: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const actions = {
            resetForm: resetForm({ values: "" }),
            setMessageToTrue: setMessageSent(true),
            setSubmittingToFalse: setSubmitting(false),
          }

          mail_notification("template_7b01s8s", values, actions)
        }}
      >
        {({ isSubmitting }) => (
          <Form id="enquiry-form">
            <div className="mb-8">
              <label className={radioStyle} htmlFor="press">
                <Field
                  type="radio"
                  name="inquiry_type"
                  id="press"
                  value="Press"
                  checked
                />{" "}
                Press
              </label>
              <label className={radioStyle} htmlFor="partnership">
                <Field
                  type="radio"
                  name="inquiry_type"
                  id="partnership"
                  value="Partnership"
                />{" "}
                Partnership
              </label>
              <label className={radioStyle} htmlFor="others">
                <Field
                  type="radio"
                  name="inquiry_type"
                  id="others"
                  value="Others"
                />{" "}
                Others
              </label>
            </div>
            <Field
              type="text"
              name="name"
              placeholder="Kindly enter your name here"
              className={inputStyle}
              autofocus="autofocus"
            />
            <Field
              type="text"
              name="organization"
              placeholder="Organization"
              className={inputStyle}
            />
            <Field
              type="text"
              name="question"
              placeholder="Please state your question or suggestion here"
              className={inputStyle}
            />
            <Field
              type="email"
              name="email"
              placeholder="Your email address, so that we can write you back"
              className={inputStyle}
            />
            <Button text={isSubmitting ? "Hang on.." : "Send"} submit />
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default InquiryForm
