import React from "react"
import ctl from "@netlify/classnames-template-literals"
import PropTypes from "prop-types"
import BirdIcon from "../../../../icons/bird-icon"
import { graphql, useStaticQuery } from "gatsby"

const ShareNow = tweet => {
  const url = `https://africachallengerbrands.com`
  const window_url = `https://twitter.com/intent/tweet?text=${tweet}&url=${url}&via=FourthCanvas`
  window.open(window_url, "popup", "width=600,height=600")
}

const ShareButton = () => {
  const tweetsQuery = useStaticQuery(graphql`
    {
      allContentfulTweets(filter: { showInPopup: { eq: true } }) {
        edges {
          node {
            message
          }
        }
      }
    }
  `)

  const tweets = tweetsQuery.allContentfulTweets.edges

  const no_of_tweets = tweets.length

  const RANDOM_TWEET_INDEX = Math.floor(Math.random() * no_of_tweets)

  const random_tweet = tweets[RANDOM_TWEET_INDEX].node.message

  const tweetButtonStyle = ctl(`
  bg-twitter-blue
  flex
  items-center
  share_item_container
  focus:outline-none
  `)

  const tweetTextStyle = ctl(`
  ml-2
  block
  md:text-[21px]
  font-light
  `)

  return (
    <button className={tweetButtonStyle} onClick={() => ShareNow(random_tweet)}>
      <BirdIcon />
      <span className={tweetTextStyle}>Share now</span>
    </button>
  )
}

ShareButton.propTypes = {}

export default ShareButton
