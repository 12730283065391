import React, { useEffect, useRef } from "react"
import { playPopupAnimation } from "./animation"
import ctl from "@netlify/classnames-template-literals"
import Share from "./share"
import CloseButton from "./close-button"

const Popup = ({
  topText = "",
  bottomText = "",
  isOpen,
  closePopup,
  showShareNow = true,
}) => {
  const popup_container = useRef()
  const popup_content = useRef()

  useEffect(() => {
    playPopupAnimation(
      popup_container.current,
      !isOpen,
      popup_content.current.children
    )
  }, [isOpen])

  const textStyle = ctl(`
    font-serif 
    font-semibold
    md:px-8
    md:pt-8
    bg-black
    leading-none
    inline-block
    text-white
    px-6
    pt-4
    pb-0
    md:text-7xl 
    text-5xl
    scale-0 
    opacity-0
    `)
  return (
    <section
      className={`transform h-screen  scale-0  w-full bg-white bg-opacity-30 backdrop-filter backdrop-blur-sm  fixed z-50  top-0 left-0  items-center justify-center flex md:pl-0 pl-7 `}
      ref={popup_container}
    >
      <div className="  " ref={popup_content}>
        <h1 className={textStyle + " relative"}>
          {topText}
          <CloseButton 
          closePopup={closePopup}
          extraStyle={`md:top-[-48px] top-[-28px] md:right-[-56px] right-[-33px]`}
           />
        </h1>
        <br />
        <h1 className={textStyle + ` md:-mt-5 -mt-[1px] md:w-auto w-full`}>
          {bottomText}
        </h1>
        {showShareNow ? <Share /> : null}
      </div>
    </section>
  )
}

Popup.propTypes = {}

export default Popup
