import React from "react"
import ctl from "@netlify/classnames-template-literals"
import ShareButton from "./button"

const Share = () => {
  const shareTextStyle = ctl(`
    bg-black
    share_item_container
    max-w-max
    
    `)

  const shareTextStylePc = ctl(`
${shareTextStyle}
md:block
hidden
mt-4
`)

  const shareTextStyleMobile = ctl(`
${shareTextStyle}
md:hidden
block
mt-0
first:pb-0
first:mt-3
last:max-w-full
`)

  const shareText = [
    "Spread the word!",
    "Share a tweet with friends and colleagues",
  ]

  return (
    <>
      {/* First and second part of the text concatenated */}
      <p className={shareTextStylePc}>{`${shareText[0]} ${shareText[1]}`}</p>
      <div>
        {/* First part of the text */}
        <p className={shareTextStyleMobile}>{shareText[0]}</p>
        {/* Second part of the text */}
        <p className={shareTextStyleMobile}> {shareText[1]} </p>
      </div>

      <ShareButton />
    </>
  )
}

export default Share
