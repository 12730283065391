import React from "react"
import Layout from "../components/layout"
import InquiryForm from "../components/pages/inquiry"
import PageHeader from "../components/utils/page-header"

const Inquiry = ({ location }) => {
  return (
    <Layout title="Inquiry" url={location.pathname}>
      <PageHeader title="Inquiry" />
      <InquiryForm />
    </Layout>
  )
}

export default Inquiry
